import React, { useState } from "react"
import {Card} from "antd"
import * as styles from "./PagesLayout.module.less"
import { mTimestampValue, getFormattedTimestampStr } from "./HelperComponents"

const TimerValues = () => {
  const [timestampValue, updateTimestamp] = useState(mTimestampValue())

  setTimeout(() => {
    updateTimestamp(mTimestampValue())
  }, 0)
  return (<div>
    <p className={styles.formattedDateDisplayStyle} style={{ color: "white", margin:0, fontWeight: "bold" }}>
      {getFormattedTimestampStr(timestampValue)}
    </p>
    <p className={styles.timestampDisplayStyle} style={{ color: "white", margin:0, fontWeight: "bold" }}>
      {parseInt(timestampValue/1000, 10)}
    </p>
    <p style={{ color: "white", fontWeight: "bold" }}>
      Hex timestamp in Seconds : {(parseInt(timestampValue/1000, 10)).toString(16)}
    </p>
    <p style={{ color: "white", fontWeight: "bold" }}>
      Unix / Epoch timestamp in Milli-Seconds : {timestampValue}
    </p>
  </div>)
}

const CustomClockCard = ({ title }) => {

  return (
    <div >
      <Card
        className={styles.clockCardStyle}
        style={{
          background: "black",
          width: "100%",
          height: "auto",
          cursor: "pointer",
          color: "white",
          textAlign: "center",
          padding: "15px",
          borderRadius: "25px",
          boxShadow: "3px 3px 10px #7f3468, 0em 0 .4em #7f3468",

          h1: {
            color: "#FFF",
            transform: "translateZ(100px)"
          },

          h2: {
            color: "#FFF",
            transform: "translateZ(500px)"
          },

          p: {
            color: "#FFF",
            transform: "translateZ(50px)"
          }
        }}
      >
        <TimerValues/>
      </Card>

    </div>
  )
}

export default CustomClockCard
