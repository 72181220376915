import React from "react"
import Container from "../components/container"
import Seo from "../components/Seo"
import CustomHeader from "../components/customHeader"
import CustomClockCard from "../components/CustomClockCard"
import SideBarView from "../components/SideBarView"
import { Link as GatsbyLink } from "gatsby"
import { styled } from '@mui/system';
import { Grid } from "@mui/material"

const Link = styled(props => <GatsbyLink {...props} />)({
  color: "#69c",
  textDecoration: "none",
  "&:hover": { color: "#8fc9ff" }
})

const UnixTimeClock = () => {

  return (
    <Container>
      <CustomHeader title={`Unix / Epoch Clock`}/>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12} md={9} xl={9}>
          <p><b>Unix time</b> (also known as Epoch time, POSIX time, seconds since the Epoch, or UNIX Epoch time) is a
            system for describing a point in time. It is the number of seconds that have elapsed since <b>1st January
              1970</b>, minus leap seconds. <b>Leap seconds</b> are ignored, with a leap second having the same Unix
            time as the second before it, and every day is considered as if it contains exactly <b>86400 seconds</b>.
          </p>
          <br/>
          <CustomClockCard style={{ with: "80%", height: "500px" }}/>
          <br/>
          <br/>
          <h4
            style={{
              color: "#4d3267",
              fontWeight: "bold",
              fontFamily: "Karla, sans-serif",
              marginTop: "20px"
            }}
          >
            Note:
          </h4>
          <p>The <Link target={`blank`} to={`https://en.wikipedia.org/wiki/Epoch`}>epoch</Link> is Unix time 0 (midnight 1/1/1970), but
            'epoch' is used as a synonym for Unix time. Some systems store epoch dates as a signed <b>32-bit integer</b>,
            which might cause problems on January 19, 2038 (known as the Year 2038 problem or <b>Y2038</b>). The
            converter on this page converts timestamps in seconds <b>(10-digit)</b>, milliseconds <b>(13-digit)</b> and
            microseconds <b>(16-digit)</b> to readable dates.</p>
        </Grid>
        <Grid item xs={12} sm={12} md={3} xl={3}>
          <SideBarView/>
        </Grid>
      </Grid>

    </Container>
  )
}
export default UnixTimeClock

export const Head = () => {
  const siteMetadata = {
    description: "Displays the unix time clock with timestamp in seconds, milliseconds, hex format",
    description2: "",
    keywordsContent: "clock, epoch, unix, leap, hex time, seconds, milli seconds"
  }
  return (
    <Seo
      title="Unix / Epoch Clock"
      canonicalUrl={"https://www.epochconverter.io/unix-time-clock/"}
      siteMetadata={siteMetadata}
    />
  )
}
